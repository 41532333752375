export const defaultTheme = {
  colors: {
    "base-white": "#FFFFFF",
    "base-background": "#FAFAFA",
    "base-card": "#D4E8E2",
    "base-input": "#EDEDED",
    "base-button": "#E6E5E5",
    "base-hover": "#D7D5D5",
    "base":"#015E49",
    "baselight":"#77b7a6",
    "basedark":"#1E3932",
    "base-error": "#f84747",
    "brand-purple-light": "#EBE5F9",
  },
  textSizes: {
    "title-title-xl": "3rem",
    "title-title-l": "2rem",
    "title-title-m": "1.5rem",
    "title-title-s": "1.2rem",
    "title-title-xs": "1.125rem",
    "text-regular-l": "1.25rem",
    "text-bold-l": "1.25rem",
    "text-regular-m": "1rem",
    "text-bold-m": "1rem",
    "text-regular-s": "0.875rem",
    "text-bold-s": "0.75rem",
    "components-tag": "0.625rem",
    "components-button-g": "0.875rem",
    "components-button-s": "0.75rem",
  },
  fonts: {
    regular: "'Roboto'",
    title: "'Baloo 2'",
  },
};
